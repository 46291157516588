import { path, range, pick } from 'ramda'
import { hasPublicWebcam, hasWebcam, isSomething } from './utils'

export const isWxw = path(['settings', 'external', 'wxw'])

export const wxwDevices = (device) => {
  return (path(['devices'], device) || []).filter(isWxw)
}

export const hasWxw = (device) => {
  return wxwDevices(device).length > 0
}

export const hasWxwConfig = (currentDevice) => {
  const wxwConfig = path(['settings', 'wxw'], currentDevice)
  return wxwConfig && wxwConfig.layouts && wxwConfig.layouts.length > 0
}

export const wxwWidgetCount = layout => {
  const layoutCounts = [
    6,
    6,
    7,
    7
  ]
  return layoutCounts[layout] || 0
}

// really we probably want to construct this object manually
// export const WXW_WIDGETS = mapObjIndexed((value, key) => {
//   if (path(['share', 'paths'], value)) {
//     return Object.assign({}, value, {
//       paths: value.share.paths.map(p => p.filter(p => p !== 'currentDevice')).filter(p => p.includes('lastData'))
//     })
//   }
//   return value
// }, Object.assign({}, WIDGET_CONFIG))
// delete WXW_WIDGETS.tempkestrel

export const WXW_WIDGETS = {
  uv: {
    title: 'UV',
    params: ['uv']
  },
  humidity: {
    title: 'Humidity',
    params: ['humidity']
  },
  temp: {
    title: 'Temperature',
    params: ['tempf']
  },
  'dew-point': {
    title: 'Dew Point',
    params: ['dewPoint']
  },
  solarradiation: {
    title: 'Solar Radiation',
    params: ['solarradiation']
  },
  wind: {
    title: 'Wind',
    params: ['windspeedmph', 'winddir']
  },
  'wind-rose': {
    title: 'Wind Rose',
    params: ['windspeedmph', 'winddir']
  },
  pressure: {
    title: 'Pressure',
    params: ['baromrelin']
  },
  co2: {
    title: 'CO2 Indoor AQ',
    params: ['co2_in_aqin'],
    url: 'https://ambientweather.com/indoor-wireless-air-quality-monitor-aqin'
  },
  leak: {
    title: 'Leak',
    any: ['leak1', 'leak2', 'leak3', 'leak4'],
    url: 'https://ambientweather.com/wh31la-leak-detector'
  },
  gdd: {
    title: 'Growing Degree Days',
    params: ['gdd']
  },
  webcam: {
    title: 'Webcam',
    test: hasWebcam,
    url: 'https://ambientweather.com/wifi-weather-camera-wc-fmwac2k'
  },
  etos: {
    title: 'Evapotranspiration',
    params: ['etos', 'etrs']
  },
  rain: {
    title: 'Rainfall',
    any: ['dailyrainin', 'hourlyrainin', 'eventrainin', 'weeklyrainin', 'monthlyrainin', 'yearlyrainin']
  },
  indoor: {
    title: 'Indoor Temp + RH',
    params: ['tempinf', 'humidityin'],
    url: 'https://ambientweather.com/amwh32m.html'
  },
  'outdoor-temp-hum': {
    title: 'Temp + RH, Array',
    params: ['tempf', 'humidity']
  },
  leafwetbin: {
    title: 'Leaf Wetness',
    params: ['leafwet1x']
  },
  forecast: {
    title: 'Forecast',
    useDefaultLocation: true
  },
  pm25: {
    title: 'PM2.5 Outdoor',
    params: ['pm25'],
    url: 'https://ambientweather.com/pm25-wireless-outdoor-particulate-monitor'
  },
  pm25_in: {
    title: 'PM2.5 Indoor',
    params: ['pm25_in'],
    url: 'https://ambientweather.com/indoor-wireless-air-quality-monitor-aqin'
  },
  aqin: {
    title: 'Air Quality',
    params: ['pm25_in_aqin'],
    url: 'https://ambientweather.com/indoor-wireless-air-quality-monitor-aqin'
  },
  lightning: {
    title: 'Lightning',
    params: ['lightning_day'],
    url: 'https://ambientweather.com/wh31l-lightning-detector'
  },
  outdoor: {
    title: 'Outdoor Temperature Summary',
    params: ['tempf']
  },
  'sun-moon': {
    title: 'Sun & Moon'
  },
  currentcast: {
    title: 'Currentcast',
    useDefaultLocation: true
  },
  daycast: {
    title: 'Daycast',
    useDefaultLocation: true
  },
  'weekly-forecast': {
    title: 'Weekly Forecast',
    useDefaultLocation: true
  },
  'daily-forecast': {
    title: 'Daily Forecast',
    useDefaultLocation: true
  },
  time: {
    title: 'Time',
    useDefaultLocation: true
  }
}
range(1, 11).forEach(i => {
  WXW_WIDGETS[`soil${i}`] = {
    title: `Soil Moisture ${i}`,
    params: [`soilhum${i}`],
    url: 'https://ambientweather.com/wh31sm-soil-moisture-sensor'
  }
  WXW_WIDGETS[`soiltens${i}`] = {
    title: `Soil Tension ${i}`,
    params: [`soiltens${i}`]
  }
  WXW_WIDGETS[`sensor${i}`] = {
    title: `Sensor ${i}`,
    params: [`temp${i}f`]
  }
})

export const getAvailWidgets = currentDevice => {
  return pick(Object.keys(WXW_WIDGETS).filter(type => {
    const every = WXW_WIDGETS[type].params && WXW_WIDGETS[type].params.every(p => isSomething(path(['lastData', p], currentDevice)))
    const any = WXW_WIDGETS[type].any && WXW_WIDGETS[type].any.some(p => isSomething(path(['lastData', p], currentDevice)))
    const all = !WXW_WIDGETS[type].params && !WXW_WIDGETS[type].any && !WXW_WIDGETS[type].test
    const test = WXW_WIDGETS[type].test && WXW_WIDGETS[type].test(currentDevice)
    return every || any || all || test
  }), WXW_WIDGETS)
}

export const getWxwWidgetSize = (layout, i) => {
  if (layout === 0) return 'medium'
  if (layout === 1) return [0, 5].includes(i) ? 'large' : 'small'
  if (layout === 2) return i === 2 ? 'large' : 'small'
  if (layout === 3) {
    if ([0, 2].includes(i)) return 'long'
    if (i === 1) return 'large'
    return 'small'
  }
}

export const canWxwWidgetGoHere = (layout, type, i) => {
  const widgetSize = getWxwWidgetSize(layout, i)
  if (type === 'outdoor') return ['large', 'medium'].includes(widgetSize)
  if (['forecast', 'daily-forecast', 'weekly-forecast'].includes(type)) return widgetSize === 'long'
  if (type === 'rain') return ['long', 'large', 'medium'].includes(widgetSize)
  if (layout === 3 && [0, 2].includes(i) && !['forecast', 'rain'].includes(type)) return false
  return true
}

export const doesWidgetHaveSize = (type, size) => {
  return range(0, 4).some(layout => {
    return range(0, wxwWidgetCount(layout)).some(i => {
      console.log('doesWidgetHaveSize', type, size, layout, i, getWxwWidgetSize(layout, i), canWxwWidgetGoHere(layout, type, i))
      return getWxwWidgetSize(layout, i) === size && canWxwWidgetGoHere(layout, type, i)
    })
  })
}

export const widgetUsesDefaultLocation = type => {
  return (WXW_WIDGETS[type] || {}).useDefaultLocation
}
